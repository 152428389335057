import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const processControlValves = () => {
  return (
    <Container className='my-4 burkert-img'>
      <Row>
      <img
        src='../assets/images/burkert-logo.png'
        alt='burkert logo'
        className='burkert-logo'
      />
      <Col xs={12} sm={12} md={12} lg={12}>
      <iframe width="560" height="315" className='mt-4 video' src="https://www.youtube.com/embed/b8Q02t4zaE8" title="YouTube video player" frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} className='mt-4'>
        <p>Burkert manufactures Process and Control Valves for the control of both liquids and gases.  Some of the types of valves include Ball, Diaphram, Angle-Seat, Globe, and Butterfly.  For nearly every Burkert valve, there is a diverse selection of mating actuators. 
          The basic types of activation are manual, solenoid, and pneumatic. Burkert offers activation states that are either on/off or control (with analog electrical inputs).</p>
        <p>Burkert designs their products to serves the general needs of the industry, as well as specific needs for the hygienic and cryogenic industries. 
          Burkert valves represent quality and are known to be the most sophisticated, most trusted, and most reliable in the industry.</p>
        <p>The Burkert line of Process & Control Valves include angle-seat valves, diaphragm valves, control valves, electromotive valves, and proportional valves.</p>
        <ol>
          <li>The Burkert angle-seat valves offer maximum flow rates when unseated, with low-pressure loss, and resistance to backflow. They are offered as pneumatic, electromotive, or manual activation. 
            These Burkert angle seat valves are suitable for high temperatures such as water and steam.</li>
          <li>The Burkert diaphragm valves are typically used in hygienic applications where the valve must be sterile, and non-contaminating.</li>
          <li>Burkert Electromotive valves have the advantage of precise control, required in mixing or batching. 
            A Source of air supply is not required. Given that they travel to their desired position without delay, they are used where fast speed is required.</li>
          <li>Burkert Proportional valves are used to reduce the pressure via statics, with the activation working against a spring load.</li>
        </ol>
      </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
           <li>
              <a
                href='https://eagle-sensors.com/burkert-shut-off-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/shutoff.jpg'
                  alt='Shut off valves'
                />
                <br />
                Shut-Off Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-control-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/control.png'
                  alt='Control Valves'
                />
                <br />
                Control Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-control-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/pharma.jpg'
                  alt='Hygienic / Pharma / Speciality Process Valves'
                />
                <br />
                Hygienic / Pharma / Speciality Process Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-control-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/process_automation.jpg'
                  alt='Process Valve Automation'
                />
                <br />
                Process Valve Automation
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-accessories-2/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/process_accessories.jpeg'
                  alt='Process accessories'
                />
                <br />
                Process Accessories
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default processControlValves