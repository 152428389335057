import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_7.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>When leading technology providers work together, the possibilities are endless. 
            As a Rockwell Encompass Partner for over a decade, 
            Burkert Fluid Controls has made it easier for plants to automate their process applications using Rockwell PLC, 
            but now as the need for Industry 4.0 ready devices matters more than ever before, we are making it easier than ever to pair devices 
            with control panel solutions.</p>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne