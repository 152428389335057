import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <Container>
      <h3>The page you are looking for does not exist.</h3>
      <Button className='btn-gold'><Link style={{color: '#fff', textDecoration: 'none'}}to='/'>Go Back</Link></Button>
    </Container>
  )
}

export default NotFound