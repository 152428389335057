import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Pneumatics = () => {
  return (
    <Container className='mt-4 burkert-img'>
      <Row>
        <img
          src='../assets/images/burkert-logo.png'
          alt='burkert logo'
          className='burkert-logo'
        />
        <Col xs={12} sm={12} md={12} lg={12}>
          <p className='mt-4'>
          Burkert manufactures valves for the control of both liquids and gases. 
          Some of the types of valves include Ball, Diaphram, Angle-Seat, Globe, and Butterfly. For nearly every Burkert valve, there is a diverse selection of mating actuators. 
          The basic types of activation are manual, solenoid, and pneumatic. Burkert offers activation states that are either on/off or control (with analog electrical inputs).
          </p>
          <p>
          Burkert designs their products to serves the general needs of the industry, as well as specific needs for the hygienic and cryogenic industries. 
          Burkert valves represent quality and are known to be the most sophisticated, most trusted, and most reliable in the industry.
          </p>
          <p>
          The Burkert line of Pneumatics & Process Interfaces defines a systems approach to multiple valve control. 
          A single valve island consisting of a common, air intake manifold with multiple Burkert pneumatic valves, each controlling a remote actuator via airlines. 
          The actuators can be either standard digital, or analog.  This system simplifies installation and control. 
          The high-performance automation system is available to interface with numerous Fieldbus communication protocols, including Profibus and DeviceNet. 
          Various electronic inputs and outputs are also available.
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
            <li>
              <a
                href='https://eagle-sensors.com/burkert-valve-islands/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/valve_islands.jpeg'
                  alt='Valve Islands'
                />
                <br />
                Valve Islands
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-pneumatic-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/pneumatic_valves.jpg'
                  alt='Pneumatic Valves'
                />
                <br />
                Pneumatic Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-pneumatics-process-interfaces-accessories/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/pneumatic_accessories.jpg'
                  alt='Pneumatic accessories'
                />
                <br />
                Accessories
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Pneumatics