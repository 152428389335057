import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const AdditionalProducts = () => {
  return (
    <Container className="mt-4 burkert-img">
      <Row>
        <img
          src='../assets/images/burkert-logo.png'
          alt='burkert logo'
          className='burkert-logo'
        />
      </Row>
      <hr className="mt-4" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
           <li>
              <a
                href='https://eagle-sensors.com/burkert-pneumatic-cylinder-single-acting/'
                rel='noreferrer'
                
              >
                <img
                  src='../assets/images/cylinder.jpg'
                  alt='Pneumatic Cylinder'
                />
                <br />
                Pneumatic Cylinder
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-7800/'
                rel='noreferrer'
                
              >
                <img
                  src='../assets/images/metering.jpeg'
                  alt='Speciality Metering Valves '
                />
                <br />
                Speciality Metering Valves 
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-1573/'
                rel='noreferrer'
                
              >
                <img
                  src='../assets/images/power.jpeg'
                  alt='Power / IO Box '
                />
                <br />
                Power / IO Box 
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-fittings-push-ins-2/'
                rel='noreferrer'
                
              >
                <img
                  src='../assets/images/fittings.jpeg'
                  alt='Fittings & Push-ins'
                />
                <br />
                Fittings & Push-ins
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-bbs-hygienic-fittings/'
                rel='noreferrer'
                
              >
                <img
                  src='../assets/images/bbs.jpeg'
                  alt='BBS Hygienic Fittings'
                />
                <br />
                BBS Hygienic Fittings
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-8922/'
                rel='noreferrer'
                
              >
                <img
                  src='../assets/images/software.jpeg'
                  alt='Software'
                />
                <br />
                Software
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default AdditionalProducts