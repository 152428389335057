import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Sensors = () => {
  return (
    <Container className='mt-4 burkert-img'>
      <Row>
       <img
          src='../assets/images/burkert-logo.png'
          alt='burkert logo'
          className='burkert-logo'
        />
        <hr className='mt-4' />
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
            <li>
              <a
                href='https://eagle-sensors.com/burkert-flow/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/flow.jpeg'
                  alt='Flow'
                />
                <br />
                Flow
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-level/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/level.jpeg'
                  alt='level'
                />
                <br />
                Level
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-liquid-analysis/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/liquid.jpeg'
                  alt='liquid analysis'
                />
                <br />
                Liquid Analysis
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-pressure/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/sensors_pressure.jpeg'
                  alt='pressure'
                />
                <br />
                Pressure
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-temperature/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/temperature.jpeg'
                  alt='temperature'
                />
                <br />
                Temperature
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
           <li>
              <a
                href='https://eagle-sensors.com/burkert-controllers-transmitters/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/controllers.png'
                  alt='Controllers / Transmitters'
                />
                <br />
                Controllers / Transmitters
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-armatures-analysis-sensors/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/armatures.jpeg'
                  alt='Armatures for Analysis Sensors'
                />
                <br />
                Armatures for Analysis Sensors
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Sensors