import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_1.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>The control head Type 8681 is optimized for decentralised automation of hygienic process valves. 
            Thanks to its universal adapter it can be combined with all normal commercial butterfly valves, ball valves, single and double seated valves. 
            With a decentralised automation concept, the control head takes over all pneumatic actuation, feedback and diagnostic functions including field bus communication. 
            The housing is easy to clean and features proven IP protection and chemically resistant materials for use in hygienic processing in food, beverage and 
            pharmaceutical industries. Depending on the process valve type, up to 3 pneumatic actuator chambers can be controlled independently from each other. 
            The switching speeds of both movement directions can be set separately. 
            A built-in check valve prevents incorrect switching of process valve actuator chambers which could result from back-pressure. 
            The process valve switching positions are detected by an inductive, analogue position sensor and reported to the PLC system. 
            Up to 3 switching points can be adjusted automatically by a Teach-In function. 
            Additionally a fourth switching position can be read in and fed back via an external inductive proximity switch. 
            The coloured status display signals the particular process valve switching position or indicates a diagnostic function such as maintenance required 
            status or fault conditions. The pilot valves are equipped with a manual override. 
            If the device housing is closed, the patented magnetically encoded manual override tool can be used to open the process valve from the exterior. 
            Bus communication is available with AS-interface, IO-Link or büS/CANopen.</p>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>Universal attachment for hygienic process valves</li>
            <li>Contactless position measurement system with 3 switching points (Teach-In function)</li>
            <li>Coloured status display</li>
            <li>Manual override operative with closed housing</li>
            <li>AS-Interface, IO-Link, büS/CANopen</li>
          </ul>
          <div className='pdf-section'>
             <img src="../assets/images/pdf-icon.png" className='icons' alt="pdf icon" />
             <a href="../datasheets/8681-Spec-Sheet.pdf" target='_blank' className='gold'>8681 Spec Sheet</a>
             <p className='mt-4'>Contact us with questions or for more information. </p>
          </div>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne