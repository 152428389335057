import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NewProducts = () => {
  return (
    <>
      <Container className='new-products-container'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_1.png" alt="article 1" />
            <br />
            <br />
            <h4 className='gold'><b>Type 8681 Universal Control Head</b></h4>
            <p>The Burkert Type 8681 control head is optimized for decentralized automation of hygienic process valves. 
              Thanks to its universal adapter it can be combined with all normal commercial butterfly valves, ball valves, single and double seated valves.</p>
            <Link to='/article-type8681' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_2.png" alt="article 2" />
            <br />
            <br />
            <h4 className='gold'><b>Type 8694 Positioner-Controller</b></h4>
            <p>Now with AOI available for Rockwell Automation RSLogix 5000/Studio 5000 Logix Designer, the Burkert type 8694 compact positioner for integrated mounting on pneumatically operated process valves. 
              Remote set point adjustment via a 4 - 20 mA signal or through AS-Interface. 
              A contact-free analogue position sensor measures the position of the valve spindle. 
              Simple installation and valve position indication is shown through LED components and optional analogue position feedback can be integrated.</p>
            <Link to='/article-type8694' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_3.png" alt="article 3" />
            <br />
            <br />
            <h4 className='gold'><b>Type 8691 Control Head</b></h4>
            <p>Now with AOI available for Rockwell Automation RSLogix 5000/Studio 5000 Logix Designer, the Burkert Type 8691 control head is designed for decentralized automation of process valves.</p>
            <Link to='/article-type8691' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_4.png" alt="article 4" />
            <br />
            <br />
            <h4 className='gold'><b>Type 8792 – Digital Electro-Pneumatic Positioner: SideControl</b></h4>
            <p>The digital electro-pneumatic positioner, SideControl, can be operated with all standard current and voltage standard signals and can also optionally be equipped with the fieldbus interface.</p>
            <Link to='/article-type8792' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_5.png" alt="article 5" />
            <br />
            <br />
            <h4 className='gold'><b>Type 8692 Positioner-Controller</b></h4>
            <p>Now with AOP available for Rockwell Automation RSLogix 5000/Studio 5000 Logix Designer, 
              the Burkert  Type 8692 compact process positioner-controller device is optimized for 
              integrated mounting on pneumatic actuators and especially suited for the requirements of a hygienic process environment.</p>
            <Link to='/article-type8692' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_6.png" alt="article 6" />
            <br />
            <br />
            <h4 className='gold'><b>Type 2000 – Pneumatically operated 2/2 way angle seat valve CLASSIC</b></h4>
            <p>The externally piloted angle seat valve is operated with a single or double–acting piston actuator.</p>
            <Link to='/article-type2000' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_7.png" alt="article 7" />
            <br />
            <br />
            <h4 className='gold'><b>Process Automation Solutions with Rockwell AOP/AOI</b></h4>
            <p>A wide range of Burkert process automation solutions with Rockwell AOP/AOI – Industry 4.0 Ready When You Are!</p>
            <Link to='/article-process-automation-solutions' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_8.png" alt="article 8" />
            <br />
            <br />
            <h4 className='gold'><b>Burkert and Eagle Sensors worked together to help Texas Water Department</b></h4>
            <p>Eagle Sensors and Controls helped a Texas water department during the horrid snowstorm with the power of Burkert solenoid valves.</p>
            <Link to='/article-burkert-eagle' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_9.png" alt="article 9" />
            <br />
            <br />
            <h4 className='gold'><b>New Burkert FLOWave SAW Flowmeter</b></h4>
            <p>Single unit measures & LED Display of 5 key process parameters.</p>
            <Link to='/article-flowave-saw-flowmeter' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img src="../assets/images/burkert_article_10.png" alt="article 10" />
            <br />
            <br />
            <h4 className='gold'><b>Eliminate High Maintenance Linkage</b></h4>
            <p>Eliminate high-maintenance linkage by upgrading your existing pancake control valve.</p>
            <Link to='/eliminate-high-maintenance-linkage' className='product-links'>Read More</Link>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default NewProducts