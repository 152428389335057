import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SolenoidValves = () => {
  return (
    <Container className='mt-4'>
      <img
        src='../assets/images/burkert-logo.png'
        alt='burkert logo'
        className='burkert-logo'
      />
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>
            Burkert manufactures valves for the control of both liquids and
            gases. Some of the types of valves include Ball, Diaphram,
            Angle-Seat, Globe, and Butterfly. For nearly every Burkert valve,
            there is a diverse selection of mating actuators. The basic types of
            activation are manual, solenoid, and pneumatic. Burkert offers
            activation states that are either on/off or control (with analog
            electrical inputs).
          </p>
          <p>
            Burkert designs their products to serves the general needs of the
            industry, as well as specific needs for the hygienic and cryogenic
            industries. Burkert valves represent quality and are known to be the
            most sophisticated, most trusted, and most reliable in the industry.
          </p>
          <p>
            The Burkert line of Solenoid Valves is very diverse. Port sizes
            available range from 11/32 to 2.5 inch. Choices of operation are
            direct, piloted, and zero differential. Connection types are DIN
            connector, spade terminals, or wire leads. Burkert Solenoid Valves
            are available with pressure ratings up to 1450 psi, and temperatures
            up to 356 degrees F. Both 2 and 3-way solenoid valves are offered.
            Burkert Solenoid Valves functionalities are either normally open,
            normally closed, or proportional. With their Solenoid Valves offered
            in different housings and diaphragm materials to ensure
            compatibility with the media being controlled, depending on the
            model selected, they can control water or steam, or most neutral,
            aggressive, or contaminated media.
          </p>
        </Col>
      </Row>
      <Row className='solenoid-list'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/3S9lSMjKod0'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen
            className='center-img video'
          ></iframe>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={6}>
          <h5>
            <u>Key Features:</u>
          </h5>
          <h6 className='mt-4'>
            <u>Type 330/331 Solenoids & coils on Type 5282 and Type 0142</u>
          </h6>
          <ul>
            <li>
              Diaphragm boot provides complete separation of fluid from solenoid
              coil and armature chamber
            </li>
            <li>
              Eliminates sticking valves due to calcium buildup on plunger/core
              tube area
            </li>
            <li>
              Unique pivoting-armature design with protective boot for longer
              life
            </li>
            <li>
              Different diaphragm elastomers available to meet customer
              requirements
            </li>
            <li>Manual Override</li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <h5>
            <u>Key Features:</u>
          </h5>
          <h6 className='mt-4'>
            <u>Type 5282 & Type 0142</u>
          </h6>
          <ul>
            <li>Hole-less solenoid diaphragm; no weak point.</li>
            <li>
              Drilled ports in machined solenoid body with needle valves
              provide:
              <ul>
                <li>Diaphragm pressure equalization</li>
                <li>
                  Adjustable speed control Type 5282 (valve opening and closing
                  speeds)
                </li>
              </ul>
            </li>
            <li>
              Different diaphragm elastomers available to meet customer
              requirements
            </li>
            <li>Manual Override</li>
          </ul>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id='tabs'>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-2-2-general-purpose-solenoid-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/2by2.jpg'
                  alt='General purpose 2/2'
                />
              </a>
              <br />
              General Purpose 2/2 Solenoids
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-general-purpose-3-2-solenoid-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/3by2.jpg'
                  alt='General purpose 3/2'
                />
              </a>
              <br />
              General Purpose 3/2 Solenoids
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-water-solenoid-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/water_solenoids.jpg'
                  alt='water solenoids'
                />
              </a>
              <br />
              Water Solenoid Valves
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-hazardous-location-solenoids/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/hazardous.jpg'
                  alt='hazardous location'
                />
              </a>
              <br />
              Hazardous Location Solenoids
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-steam-solenoid-valves/'
                rel='noreferrer'
              >
                <img src='../assets/images/steam.jpg' alt='Steam solenoid' />
              </a>
              <br />
              Steam Solenoid Valves
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id='tabs'>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-high-pressure-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/high_pressure.jpg'
                  alt='High Pressure'
                />
              </a>
              <br />
              Steam Solenoid Valves
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-cryogenic/'
                rel='noreferrer'
              >
                <img src='../assets/images/cryogenic.png' alt='Cryogenic' />
              </a>
              <br />
              Cryogenic
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-media-separated/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/media_separated.jpg'
                  alt='Media separated'
                />
              </a>
              <br />
              Media Separated
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-solenoid-valve-advantages/'
                rel='noreferrer'
              >
                <img src='../assets/images/advantages.jpg' alt='Advantages' />
              </a>
              <br />
              Burkert Solenoid Valve Advantages
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-accessories/'
                rel='noreferrer'
              >
                <img src='../assets/images/accessories.jpg' alt='Advantages' />
              </a>
              <br />
              Accessories
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default SolenoidValves;
