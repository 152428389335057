import React from 'react'
import { useRef } from 'react'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

const sendEmail = () => {

  emailjs.sendForm('service_ummq5p4', 'template_0iq9fti', form.current, 'y5DX-2w0htDOGF5el')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });

  
}
  return (
  <Container className='mt-4'>
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Card className='contact-card'>
          <Row>
            <Col xs={12} sm={12} md={12} lg={4}>
              <img src="../assets/images/excel_office.jpg" alt="Excel Office" className='office-img'/>
              <h6 className='gold center'><b>Cleveland Office & Warehouse <br />Accounts Payable, Receivable, Purchasing</b></h6>
              <ul className='contact-list'>
                <li><img src="../assets/images/location_icon.png" className='icons' alt="address icon" />2967 Nationwide Pkwy Brunswick, OH 44212</li>
                <li><img src="../assets/images/phone_icon.png" className='icons' alt="phone icon" />Phone: 330-220-1977</li>
                <li><img src="../assets/images/fax_icon.jpg" className='icons' alt="fax icon" />Fax: 330-220-1987</li>
                <li><img src="../assets/images/email_icon.png" className='icons' alt="email icon" /><a href='mailto:sales@excelautomationinc.com'>sales@excelautomationinc.com</a></li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <img src="../assets/images/eagle_office.jpg" alt="Eagle Office" className='office-img'/>
              <h6 className='gold center'><b>Kansas City Office & Warehouse</b></h6>
              <ul className='contact-list'>
                <li><img src="../assets/images/location_icon.png" className='icons' alt="address icon" />8393 Melrose Dr. Lenexa, KS 66214</li>
                <li><img src="../assets/images/phone_icon.png" className='icons' alt="phone icon" />Phone: 913-599-2444</li>
                <li><img src="../assets/images/fax_icon.jpg" className='icons' alt="fax icon" />Fax: 913-492-6799</li>
                <li><img src="../assets/images/email_icon.png" className='icons' alt="email icon" /><a href='mailto:eaglesensors@excelautomationinc.com'>eaglesensors@excelautomationinc.com</a></li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <img src="../assets/images/st_louis_office.jpg" alt="Eagle Office" className='office-img'/>
              <h6 className='gold center'><b>St. Louis, Eastern Missouri, Eastern Iowa, Illinois Sales Office</b></h6>
              <ul className='contact-list'>
                <li><img src="../assets/images/location_icon.png" className='icons' alt="address icon" />St. Louis, MO</li>
                <li><img src="../assets/images/phone_icon.png" className='icons' alt="phone icon" />Phone: 636-728-0999</li>
                <li><img src="../assets/images/email_icon.png" className='icons' alt="email icon" /><a href='mailto:eaglesensors@excelautomationinc.com'>eaglesensors@excelautomationinc.com</a></li>
              </ul>
            </Col>
          </Row>
          <Form ref={form} onSubmit={sendEmail} className='contact-form'>
            <Row>
              <div className="contact-logo">
                <img src="../assets/images/logo_version_2.png" alt="Eagle Logo" />
              </div>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="conactForm.ControlTextarea1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control type='text' required name='name' rows={3} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group
                    className="mb-3"
                    controlId="conactForm.ControlTextarea1"
                  >
                  <Form.Label>Company</Form.Label>
                  <Form.Control type='text' required name='company' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
             <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="conactForm.ControlTextarea1"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control type='text' required name='city' rows={3} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="conactForm.ControlTextarea1"
                >
                  <Form.Label>State</Form.Label>
                  <Form.Control type='text' required name='state' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group
                    className="mb-3"
                    controlId="conactForm.ControlTextarea1"
                  >
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type='phone' required name='phone' rows={3} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Form.Group
                    className="mb-3"
                    controlId="conactForm.ControlTextarea1"
                  >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type='email' required name='email' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group
                    className="mb-3"
                    controlId="conactForm.ControlTextarea1"
                  >
                  <Form.Label>How can we help?</Form.Label>
                  <Form.Control as='textarea' required name='message' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Button className='quoteBtn' onClick={sendEmail} type='submit'>Submit</Button>
          </Form>
        </Card>
      </Col>
    </Row>
  </Container>
  )
}

export default Contact