import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';

const About = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
        <img src="../assets/images/burkert_landingpage.png" className='landing-img mt-4' alt="Burkert logo" />
          <Card className='about-card'>
            <br />
            <h3 className='gold'><b>About Burkert</b></h3>
            <p>Eagle Sensors & Controls is an Authorized Distributor for Burkert Valves.
               Burkert manfacturers valves for control of both liquids and gases. 
               Some of the types of valves include Ball, Diaphram, Angle-Seat, Globe, and Butterfly. 
               For nearly every Burkert valve, there are a diverse selection of mating actuators. 
               The basic types of activation are manual, solenoid, and pneumatic. 
               Burkert offers activation states that are either on/off or control (with analog electrical inputs). 
               Burkert designs their products to serves the general needs of the industry, as well as specific needs for the hygienic and cryogenic industries. 
               Burkert valves represent quality, and are known to be the most sophisticated, most trusted, and most reliable in the industry.</p>
            <p>Burkert also offers process instrumentation & controllers, including applications for flow, mass flow, level, ph, conductivity, pressure, temperature, and various transmitters & controllers.</p>
            <p>Eagle Sensors & Controls is an authorized Burkert distributor.  Contact Eagle Sensors & Controls for your Burkert Valve or flow measurement applications.</p>
            <br />
            <h3 className="gold"><b>Authorized Distributor</b></h3>
          <p>Eagle Sensors & Controls has been a distributor of numerous products for Industrial Automation for 41 years.  
            Eagle Sensors & Controls has offices in Lenexa, Kansas, and a branch office in St. Louis, MO.  
            Our sales staff are thoroughly trained on the products, and knowledgeable about their capabilities.  
            This experience allows us to solve some of the most challenging applications in the industry. 
            Eagle Sensor & Controls became a division of Excel Automation on November, 2017.</p>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default About