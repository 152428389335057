import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_10.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>NO-linkage design.</li>
            <li>Never needs linkage adjustment over life.</li>
            <li>The positioner registers the valve position without deterioration through a contact-free position sensor.</li>
            <li>Higher repeatability & accuracy of sensed valve position</li>
            <li>Calibrate only at initial installation, or upon valve or positioner/controller replacement.</li>
            <li>Automatic X-Tune at start-up</li>
            <li>No repeated calibration/X-Tuning required</li>
            <li>Unique, NO air-leakage design</li>
            <li>Air is channeled internally from the actuator to the control head, no external tubing.</li>
            <li>The unique pilot valve system enables compressed air recycling to mitigate actuation chamber contamination from the environment.</li>
            <li>Zero Bleed rate; substantial savings in plant air consumption</li>
            <li>Burkert Control heads (i.e., Type 8692)</li>
            <li>Operate directly from plant air (58-102 psi/4-7 Bar) (actuator size dependent)</li>
            <li>Can be combined with Burkert’s Globe Control, Angle seat, or Diaphragm valves.</li>
            <li>Industry 4.0 Communications</li>
            <li>Profibus, Device-Net, Ethernet/IP, PROFINET, Modbus TCP, or büS (Burkert Bus System)</li>
          </ul>
          <h4 className='gold'><u><b>Applications</b></u></h4>
          <ul>
            <li>Burkert adaptors are available to upgrade your existing, pancake-controlled valve.</li>
            <li>Keep your existing valve body.</li>
            <li>Eliminate its high maintenance linkage.</li>
            <li>Simply install the appropriate adaptor and a new Burkert Control head with NO linkage/NO maintenance.</li>
          </ul>
          <h4 className='gold'><u><b>Housing</b></u></h4>
          <ul>
            <li>Chemically resistant stainless steel for use in hygienic processing, food, beverage & pharmaceutical industries</li>
            <li>Proven IP protection; easy to clean.</li>
            <li>Compact overall dimensions; ideal for space-constrained installations</li>
          </ul>
          <p>Directly replaces all existing I/P legacy control valve positioners; including the Pancake style shown above.</p>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne