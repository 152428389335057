import React from 'react'
import Banner from '../components/Banner'
import * as ReactBootStrap from 'react-bootstrap';
import Categories from '../components/Categories';

const Home = () => {
  return (
    <div>
      <Banner />
      <ReactBootStrap.Container>
        <h2 className='lg-title mt-6 center home-title'>Burkert Fluid Control Systems</h2>
        <hr />
        <p>Eagle Sensors & Controls is an Authorized Distributor for Burkert Valves.
           Burkert manfacturers valves for control of both liquids and gases. Some of the types of valves include Ball, Diaphram, Angle-Seat, Globe, and Butterfly. </p>
        <div className="categories">
          <Categories />
        </div>
      </ReactBootStrap.Container>
    </div>
  )
}

export default Home