import Home from '../src/pages/Home';
import NavBar from '../src/components/NavBar';
import Footer from '../src/components/Footer';
import About from '../src/pages/About';
import Contact from '../src/pages/Contact';
import NewProducts from '../src/pages/NewProducts';
import NotFound from '../src/pages/NotFound';
import SolenoidValves from './pages/SolenoidValves';
import Electromotive from './pages/Electromotive';
import Pneumatics from './pages/Pneumatics';
import Sensors from './pages/Sensors';
import Microfluidic from './pages/Microfluidic';
import MassFlow from './pages/MassFlow';
import Filtration from './pages/Filtration';
import AdditonalProducts from './pages/AdditionalProducts';
import ArticleOne from './pages/ArticleOne';
import ArticleTwo from './pages/ArticleTwo';
import ArticleThree from './pages/ArticleThree';
import ArticleFour from './pages/ArticleFour';
import ArticleFive from './pages/ArticleFive';
import ArticleSix from './pages/ArticleSix';
import ArticleSeven from './pages/ArticleSeven';
import ArticleEight from './pages/ArticleEight';
import ArticleNine from './pages/ArticleNine';
import ArticleTen from './pages/ArticleTen';
import ProcessControlValves from './pages/processControlValves';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path='/' Component={Home} exact />
          <Route path='/about' Component={About} />
          <Route path='/contact' Component={Contact} />
          <Route path='/newproducts' Component={NewProducts} />
          <Route path='*' Component={NotFound} />
          <Route path='/solenoidvalves' Component={SolenoidValves} />
          <Route path='/electromotive' Component={Electromotive} />
          <Route path='/pneumatics' Component={Pneumatics} />
          <Route path='/sensors' Component={Sensors} />
          <Route path='/microfluidic' Component={Microfluidic} />
          <Route path='/massflow-controllers' Component={MassFlow} />
          <Route path='/filtraiton-modules' Component={Filtration} />
          <Route path='/additional-products' Component={AdditonalProducts} />
          <Route path='/article-type8681' Component={ArticleOne} />
          <Route path='/article-type8694' Component={ArticleTwo} />
          <Route path='/article-type8691' Component={ArticleThree} />
          <Route path='/article-type8792' Component={ArticleFour} />
          <Route path='/article-type8692' Component={ArticleFive} />
          <Route path='/article-type2000' Component={ArticleSix} />
          <Route
            path='/article-process-automation-solutions'
            Component={ArticleSeven}
          />
          <Route path='/article-burkert-eagle' Component={ArticleEight} />
          <Route
            path='/article-flowave-saw-flowmeter'
            Component={ArticleNine}
          />
          <Route
            path='/eliminate-high-maintenance-linkage'
            Component={ArticleTen}
          />
          <Route
            path='/processcontrolvalves'
            Component={ProcessControlValves}
          />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
