import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const MassFlow = () => {
  return (
    <Container className="mt-4 burkert-img">
      <Row>
       <img
          src='../assets/images/burkert-logo.png'
          alt='burkert logo'
          className='burkert-logo'
        />
        <Col xs={12} sm={12} md={12} lg={12} className='mt-4'>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/qohKRx3FTZw" title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className='video' allowfullscreen></iframe>
        </Col>
        <hr className='mt-4'/>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
           <li>
              <a
                href='https://eagle-sensors.com/burkert-type-mass-flow-controllers/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/mass_flow_controllers.jpg'
                  alt='Mass Flow Controllers'
                />
                <br />
                Mass Flow Controllers
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-mass-flow-meters/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/meters.jpeg'
                  alt='Mass Flow Meters'
                />
                <br />
                Mass Flow Meters
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-liquid-flow-controllers/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/liquid_controllers.jpeg'
                  alt='Liquid Flow Controllers'
                />
                <br />
                Liquid Flow Controllers
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-liquid-flow-meters/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/liquid_meters.jpeg'
                  alt='Liquid Flow Meters'
                />
                <br />
                Liquid Flow Meters
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-gateway-o-modules/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/gateway.jpeg'
                  alt=' Gateway / I/O Module'
                />
                <br />
                Gateway / I/O Module
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default MassFlow