import React from 'react';
import { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {Row, Col} from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const QuickQuote = () => {
  const [show, setShow] = useState(false);
  const form = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_ra8xt7p', 'template_96mjn3r', form.current, 'y5DX-2w0htDOGF5el')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });

    setShow(false)
}

  return (
    <>
      <Button variant="primary" className='quoteBtn' onClick={handleShow}>
        Quick Quote
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Quick Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={sendEmail}>
           
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group
                className="mb-3"
                controlId="quickQuoteForm.ControlTextarea1"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control type='text' required name='name'  autoFocus placeholder='John Doe...' rows={3} />
              </Form.Group>
            </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
              <Form.Group
                className="mb-3"
                controlId="quickQuoteForm.ControlTextarea1"
              >
                <Form.Label>Company name</Form.Label>
                <Form.Control type='text' required name='company' placeholder='company...' rows={3} />
               </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control type='text' required name='city' placeholder='Cleveland...' rows={3} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>State</Form.Label>
                  <Form.Control type='text' required name='state' placeholder='Ohio...' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
               <Form.Group className="mb-3" controlId="quickQuoteForm.ControlInput1">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  name='email'
                  required
                />
               </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type='phone' required name='phone' placeholder='Phone...' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                className="mb-3"
                controlId="quickQuoteForm.ControlTextarea1"
              >
                <Form.Label>Part Number</Form.Label>
                <Form.Control type='text' required name='part_number' rows={3} />
               </Form.Group>
             
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type='phone' required name='quantity' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                className="mb-3"
                controlId="quickQuoteForm.ControlTextarea1"
              >
                <Form.Label>Part Number</Form.Label>
                <Form.Control type='text' name='part_number2' rows={3} />
               </Form.Group>
             
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type='phone' name='quantity2' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                className="mb-3"
                controlId="quickQuoteForm.ControlTextarea1"
              >
                <Form.Label>Part Number</Form.Label>
                <Form.Control type='text' name='part_number3' rows={3} />
               </Form.Group>
             
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type='phone' name='quantity3' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                className="mb-3"
                controlId="quickQuoteForm.ControlTextarea1"
              >
                <Form.Label>Part Number</Form.Label>
                <Form.Control type='text' name='part_number4' rows={3} />
               </Form.Group>
             
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type='phone' name='quantity4' rows={3} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                className="mb-3"
                controlId="quickQuoteForm.ControlTextarea1"
              >
                <Form.Label>Part Number</Form.Label>
                <Form.Control type='text' name='part_number5' rows={3} />
               </Form.Group>
             
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="quickQuoteForm.ControlTextarea1"
                >
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control type='phone' name='quantity5' rows={3} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' className='quoteBtn' type='submit' value='Send' onClick={sendEmail}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QuickQuote