import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_5.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>The compact positioner device key 8692 is optimized for integrated mounting on the pneumatic actuators in the process valve series 
            Type 23xx/2103 and is specially designed for the requirements of a hygienic process environment. 
            The pilot air duct is integrated into the actuator without external piping. 
            The easy operation and the selection of additional software functions are carried out either on a big graphic 
            display with backlight and keypad or via the COMMUNICATOR. The positioner registers the valve position without 
            deterioration via a contact-free, analog position sensor. The integrated diagnostics function is used to monitor 
            control valve operating conditions. Valve diagnostic messages via status signals are issued in accordance with NE107 (NAMUR) 
            and recorded as historical entries. The housing is easy to clean and features proven IP protection and chemically resistant 
            materials for use in hygienic processing in the food, beverage, and pharmaceutical industries. Combined with Bürkert ELEMENT actuators, 
            the pneumatic actuating system enables spring chamber aeration that avoids actuator chamber contamination from the environment.</p>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>Compact and robust stainless steel design</li>
            <li>Easy start-up with automatic X-Tune function</li>
            <li>Contact-free position sensor</li>
            <li>Integrated pilot air duct with spring chamber aeration</li>
            <li>EtherNet/IP, PROFINET, Modbus TCP, PROFIBUS DP-V1 or Bürkert system bus (büS)</li>
          </ul>
          <div className='pdf-section'>
             <img src="../assets/images/pdf-icon.png" className='icons' alt="pdf icon" />
             <a href="../datasheets/8692-spec-sheet.pdf" target='_blank' className='gold'>Type 8692-Data Sheet</a>
             <p className='mt-4'>Contact us with questions or for more information. </p>
          </div>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne