import React from 'react';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap'; 
import { Link } from 'react-router-dom'

const Categories = () => {
  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={4}>
          <Link to='/solenoidvalves' className='category-links'>  
            <Card className='category-card'>
              <div className="card-title"><h5>Solenoid Valves</h5></div>
              <div className="card-img">
              <img src="../assets/images/solenoid.jpg" alt="solenoid valves" />
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <Link to='processcontrolvalves' className='category-links'>
            <Card className='category-card'>
              <div className="card-title"><h5>Process & Control Valves</h5></div>
              <div className="card-img">
                <img src="../assets/images/process.jpg" alt="Process control valves" />
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
        <Link to='/electromotive' className='category-links'>
          <Card className='category-card'>
            <div className="card-title"><h5>Electromotive Valves</h5></div>
            <div className="card-img">
              <img src="../assets/images/electromotive.jpeg" alt="Electromotive Valves" />
            </div>
          </Card>
        </Link> 
        </Col>
      </Row>
      <Row className='mt-4'>
      <Col xs={12} sm={12} md={12} lg={4} >
      <Link to='pneumatics' className='category-links'>
        <Card className='category-card'>
          <div className="card-title"><h5>Pneumatics & Process Interfaces</h5></div>
          <div className="card-img">
            <img src="../assets/images/pneumatics.jpg" alt="Pneumatics & Process Interfaces" />
          </div>
        </Card>
      </Link>
      </Col>
      <Col xs={12} sm={12} md={12} lg={4}>
      <Link to='sensors' className='category-links'>
        <Card className='category-card'>
          <div className="card-title"><h5>Sensors, Transmitters & Controllers</h5></div>
          <div className="card-img">
            <img src="../assets/images/sensors.jpg" alt="Sensors, Transmitters & Controllers" />
          </div>
        </Card>
      </Link> 
      </Col>
      <Col xs={12} sm={12} md={12} lg={4}>
      <Link to='/microfluidic' className='category-links'>
        <Card className='category-card'>
          <div className="card-title"><h5>Microfluidic Valve & Pumps</h5></div>
          <div className="card-img">
            <img src="../assets/images/microfluidic.jpg" alt="Microfluidic Valve & Pumps" />
          </div>
        </Card>
      </Link>
      </Col>
    </Row>
    <Row className='mt-4'>
      <Col xs={12} sm={12} md={12} lg={4} >
      <Link to='massflow-controllers' className='category-links'>
        <Card className='category-card'>
          <div className="card-title"><h5>Mass Flow Controllers</h5></div>
          <div className="card-img">
            <img src="../assets/images/mass_flow.jpeg" alt="Mass Flow Controllers" />
          </div>
        </Card>
      </Link>
      </Col>
      <Col xs={12} sm={12} md={12} lg={4}>
      <Link to='filtraiton-modules' className='category-links'>
        <Card className='category-card'>
          <div className="card-title"><h5>Filtration Modules</h5></div>
          <div className="card-img">
            <img src="../assets/images/modules.jpeg" alt="Filtration Modules" />
          </div>
        </Card>
      </Link>
      </Col>
      <Col xs={12} sm={12} md={12} lg={4}>
      <Link to='additional-products' className='category-links'>
       <Card className='category-card'>
          <div className="card-title"><h5>Additional Products</h5></div>
          <div className="card-img">
            <img src="../assets/images/additional_products.jpeg" alt="Additional Products" />
          </div>
        </Card>
      </Link>
      </Col>
    </Row>
  </>
    
  )
}

export default Categories