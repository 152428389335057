import React from 'react'
import Articles from '../components/Articles'

const NewProducts = () => {
  return (
    <>
      <Articles />
    </>
  )
}

export default NewProducts