import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Filtration = () => {
  return (
    <Container className="mt-4 burkert-img">
      <Row>
        <img
          src='../assets/images/burkert-logo.png'
          alt='burkert logo'
          className='burkert-logo'
        />
      </Row>
      <hr className='mt-4'/>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-cut-02/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/c_cut.jpeg'
                  alt='C-Cut Hollow Fibre Modules'
                />
                <br />
                C-Cut Hollow Fibre Modules
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-cut-03/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/t_cut.jpg'
                  alt='T-Cut PP Tubular Modules'
                />
                <br />
                T-Cut PP Tubular Modules
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-cut-04/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/t_cut_tubular.jpg'
                  alt='T-Cut Tubular Modules'
                />
                <br />
                T-Cut Tubular Modules
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Filtration