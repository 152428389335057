import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_4.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>The robust and compact positioner is designed with standardisation acc. 
            to IEC 60534-6-1 or VDI/VDE 3845 (IEC 60534-6-2) for assembly onto linear and rotary actuators. 
            In addition, the variant with remote position sensor can be combined with Bürkert process control valves for closed-loop control. 
            The digital electro-pneumatic positioner, SideControl, can be operated with all standard current and voltage standard signals 
            and can also optionally be equipped with the fieldbus interface. 
            The positioner is equipped with additional diagnostic functions to monitor the valve. 
            Valve diagnostic messages via status signals are issued in accordance with NE107 (NAMUR) and recorded as historical entries. 
            The diagnostics can be used to monitor the operating conditions of the control valve which makes maintenance decisions predictable and 
            optimises the availability of the plants as required. 
            Operation is carried out via the external operating and display module consisting of a graphic display with backlight. 
            For the user, this results in very simple and clear operation, which is identical to the Bürkert TopControl 8692/8693 positioners and process controllers. 
            The pneumatic actuating system can be used equally for single-acting and double-acting actuators. 
            It features a defined safety response in the event of failure of the electrical or pneumatic power supply and has an enormous air 
            capacity range with pressure supply up to 7 bar.</p>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>Compact and robust design</li>
            <li>Easy to start up using Tune function</li>
            <li>Integrated diagnostic functions for valve monitoring</li>
            <li>Dynamic actuating system with no air consumption in controlled state</li>
            <li>EtherNet/IP, PROFINET, Modbus TCP, PROFIBUS DP-V1 or Bürkert system bus (büS)</li>
          </ul>
          <div className='pdf-section'>
             <img src="../assets/images/pdf-icon.png" className='icons' alt="pdf icon" />
             <a href="../datasheets/8792-spec-sheet.pdf" target='_blank' className='gold'>Type 8792 Control Head Spec Sheet</a>
             <p className='mt-4'>Contact us with questions or for more information. </p>
          </div>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne