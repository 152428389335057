import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_3.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>The device key 8691 control head is designed for decentralised automation of ELEMENT Type 21xx pneumatic process valves. 
            The valve position is registered via a contact-free, analogue sensor element which automatically detects and saves the valve end position using the Teach 
            function during start-up. The integrated pilot valve controls single or double-acting actuators. 
            An optional communication interface, AS-Interface, IO-Link or büS (based on CANopen) can be selected. 
            The design of the control head and the actuator permits internal control air routing without external piping. 
            Besides the electrical position feedback, the device status is shown directly on the control head itself through 
            coloured powerful LEDs, even in difficult ambient conditions. 
            The housing is easy to clean and features proven IP protection and chemically resistant materials for use in hygienic processing in the food, 
            beverage and pharmaceutical industries. Focused on plant wash-down applications, the IP protection of the housing is supported by positive 
            pressure inside the control head. Combined with Bürkert ELEMENT actuators, the pneumatic actuating system enables spring chamber aeration that 
            avoids actuator chamber contamination from the environment.</p>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>Contact-free inductive valve position registration (Teach function)</li>
            <li>Colour illuminated status display</li>
            <li>Integrated pilot air duct in the actuator</li>
            <li>Fieldbus interface AS-Interface, IO-Link or Bürkert system bus (büS)</li>
            <li>With ATEX II cat. 3G/D / IECEx approval</li>
          </ul>
          <div className='pdf-section'>
             <img src="../assets/images/pdf-icon.png" className='icons' alt="pdf icon" />
             <a href="../datasheets/8691-spec-sheet.pdf" target='_blank' className='gold'>Type 8691 Control Head Spec Sheet</a>
             <p className='mt-4'>Contact us with questions or for more information. </p>
          </div>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne