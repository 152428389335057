import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Microfluidic = () => {
  return (
    <Container className='mt-4 burkert-img '>
      <Row>
        <img
          src='../assets/images/burkert-logo.png'
          alt='burkert logo'
          className='burkert-logo'
        />
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4 className="gold mt-4">2/2 & 3/2 Way Micro Solenoids and Membrane/Micro-dosing Pumps:</h4>
          <p>Reliability and repeatability in Microfluidics – Burkert offers individual control valve solutions for pressure/time dispensing and pumps for volumetric dosing.</p>
        </Col>
        <hr />
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
            <li>
              <a
                href='https://eagle-sensors.com/burkert-2-2-3-2-way-micro-solenoids/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/microsolenoids.jpeg'
                  alt='2/2 & 3/2 way Micro Solenoids'
                />
                <br />
                2/2 & 3/2 way Micro Solenoids
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-7615/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/membrane.png'
                  alt='2/2 & 3/2 way Micro Solenoids'
                />
                <br />
                Membrane / Micro-dosing Pumps
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Microfluidic