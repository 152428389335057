import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_2.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>Type 8694 – Digital electropneumatic positioner for the integrated mounting on process control valves</p>
          <p>Compact positioner for integrated mounting on pneumatically operated process valves. Remote setpoint adjustment via a 4 - 20 mA signal or through AS-Interface. A contact-free analogue position sensor measures the position of the valve spindle. Simple installation through automatic TUNE-function and setting through DIP-switch: Close tight function Characteristic curves selection Reversal of effective direction Switching manual /automatic operation Binary input Additional parametrisation options are possible through DTM devices. A software interface can be used for, amongst others, linearisation of the operation characteristics by using free programmable fixed points. 
            The valve position indication is shown through LED components. As an option an analogue position feedback can be integrated.</p>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>Compact, robust stainless steel design</li>
            <li>Start-up by automatic TUNE-function</li>
            <li>Contact-free position sensor</li>
            <li>Integrated control air routing</li>
            <li>AS-Interface, IO-Link or Bürkert System bus (büS)</li>
          </ul>
          <div className='pdf-section'>
             <img src="../assets/images/pdf-icon.png" className='icons' alt="pdf icon" />
             <a href="../datasheets/8694-spec-sheet.pdf" target='_blank' className='gold'>8694 Positioner-Controller Spec Sheet</a>
             <p className='mt-4'>Contact us with questions or for more information. </p>
          </div>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne