import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_6.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={6}>
        <p>The externally piloted angle seat valve is operated with a single or double–acting piston actuator. The actuator is available in two different materials, depending on the ambient temperature. High flow rates are attained with the virtually straight flow path. The reliable self–adjusting packing gland provides high sealing integrity. 
            These maintenance-free and robust valves can be retrofitted with a comprehensive range of accessories for position indication, stroke limitation or manual override.</p>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>Stainless steel or gunmetal housing with threaded, clamped or welded connection</li>
            <li>Long service life</li>
            <li>Contact-free position sensor</li>
            <li>High flow rate</li>
            <li>Robust actuators with modular accessory program</li>
          </ul>
          <div className='pdf-section'>
             <img src="../assets/images/pdf-icon.png" className='icons' alt="pdf icon" />
             <a href="../datasheets/2000-spec-sheet.pdf" target='_blank' className='gold'>Type 2000 Pneumatically Operated Spec Sheet</a>
             <p className='mt-4'>Contact us with questions or for more information. </p>
          </div>
          <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/x0jliOV-2qg" title="YouTube video player" frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Col>
      </Row>
      
    </Container>
  )
}

export default ArticleOne