import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import QuickQuote from '../components/QuickQuote'
import DropdownMenu from '../components/DropdownMenu'

const Footer = () => {
  return (
    <div className='footer'>
      <Container className='footer-container'>
      <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div className="left-container">
              <img src="../assets/images/footer_image_white_gold.png" alt="Eagle Logo" className='footer-logo'/>
              <h5 className='white'>913-599-2444</h5>
              <h5 ><a className='white' href="mailto:eaglesensors@excelautomationinc.com">eaglesensors@excelautomationinc.com</a></h5>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <ul className='footer-list'>
              <Link to='/' className='footer-link'><li className="footer-list-item">Home</li></Link>
              <Link to='/about' className='footer-link'><li className="footer-list-item">About Burkert</li></Link>
              <Link to='/newproducts' className='footer-link'><li className="footer-list-item">Featured Products</li></Link>
              <Link to='/contact' className='footer-link'><li className="footer-list-item">Contact</li></Link>
              <li><DropdownMenu /></li>
              <li><QuickQuote /></li>
            </ul>
          </Col>
        </Row>
        
      </Container>
      <div className="copyright-section">
          <p>Website designed by XL Web Technologies</p>
      </div>
    </div>
  )
}

export default Footer