import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_9.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>Burkert FLOWave SAW (Surface Acoustic Wave) Flowmeter.</p>
          <h4 className='gold'><u><b>Key Features</b></u></h4>
          <ul>
            <li>100% Non-invasive/hygienic/zero pressure drop</li>
              <ul>
                <li>Flow & Velocity</li>
                <li>Acoustic Wave Transmission %</li>
                <li>Density Factor</li>
                <li>Temperature</li>
                <li>Totalization</li>
              </ul>
            <li>Compact small size</li>
            <li>Without any parts in the measuring tube – Just A TUBE</li>
            <li>Installation in any orientation: Vertical, horizontal or other</li>
            <li>Lightweight; 1-person installation</li>
            <li>Visual ISO Ring LED indicator</li>
            <li>CIP/SIP capable</li>
            <li>Measurements independent of media conductivity</li>
            <li>Communications & analog outputs (Ethernet IP, PROFINET, MODBUS TCP and More)</li>
          </ul>
          <h4 className="gold"><u><b>Examples of challenging sensing applications:</b></u></h4>
          <ul>
            <li>Beer – WART transfer – TRUB/Beer interphase</li>
            <li>NON-Conductive liquid applications – WFI RO & DI waters, OIL, and more</li>
            <li>Phase Change – Instantaneous changes in media composition/concentration</li>
          </ul>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne