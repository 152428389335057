import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const ArticleOne = () => {
  return (
    <Container className='mt-4'>
      <Row>
        <img src="../assets/images/burkert_article_8.png" alt="article 1" className='center-img' style={{width: '70%'}}/>
      </Row>
      <Row className='mt-4'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>Eagle Sensors and Controls helped a Texas water department during the horrid snowstorm with the power of Burkert solenoid valves.</p>
          <p>Due to the unprecedented inclement weather experienced in Texas, Burkert has received an influx of requests for valve replacements for both Burkert solenoid valves and those of our competitors. 
            These workhorses simply could not handle the extreme temperatures and now is the opportunity to future-proof those replacement valves.</p>
          <p>Fred Saylor of Eagle Sensors worked personally on helping a water department in Texas.</p>
          <p>“… The Texas water department froze up and this valve was damaged beyond repair. It was obsolete, so I worked with Burkert to find the perfect replacement. The problem was it had a 17-business day lead time. But Burkert helped me find a different solenoid valve that met the requirements and was in stock. Eagle Sensors got the order in and the Solenoid Valve was sent directly to the customer. 
            This will get a critical piece of the water departments process equipment back up and running.”</p>
          <p>Helping customers specify Burkert valves can resolve the problems with low ambient temperatures not just for now, but also well into the future should we see these record temperatures again.</p>
        </Col>
      </Row>
      <Button className='quoteBtn'><Link to='/newproducts' style={{textDecoration: 'none', color: '#fff'}}>Back to New Products</Link></Button>
    </Container>
  )
}

export default ArticleOne