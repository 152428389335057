import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ReactBootStrap from 'react-bootstrap';
import QuickQuote from '../components/QuickQuote';
import DropdownMenu from '../components/DropdownMenu';

export default function NavBar() {
  return (
    <div>
        <ReactBootStrap.Navbar className='navbar' collapseOnSelect expand='lg' variant='dark'>
          <ReactBootStrap.Container> 
            <ReactBootStrap.Navbar.Brand href='/'>
              <ReactBootStrap.Image
                src='../assets/images/logo_white_gold.png'
                alt='Burkert Logo'
                className='logo'
              ></ReactBootStrap.Image>
            </ReactBootStrap.Navbar.Brand>
            <ReactBootStrap.Navbar.Toggle aria-controls='responsive-navbar-nav'/>
            <ReactBootStrap.Navbar.Collapse
              
              id='responsive-navbar-nav'
            >
            <ReactBootStrap.Nav className='ml-auto'>
              <ReactBootStrap.Nav.Link className='nav-link' href='/' >
                <h6>Home</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link className='nav-link' href='/about'>
                <h6 >About Burkert</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link className='nav-link' href='/newproducts'>
                <h6 >Featured Products</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link className='nav-link' href='/contact'>
                <h6 >Contact</h6>
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link>
                <DropdownMenu />
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link className='nav-link' href=''>
                <QuickQuote />
              </ReactBootStrap.Nav.Link>            
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Container>
      </ReactBootStrap.Navbar>
    </div>
  );
}