import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

const DropdownMenu = () => {
  return (
    <Dropdown>
      <Dropdown.Toggle className='background-gold' style={{border: 'none'}} id="dropdown-basic">
        Products
      </Dropdown.Toggle>

      <Dropdown.Menu >
        <Dropdown.Item><Link className='dropdown-links' to="/SolenoidValves">Solenoid Valves</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/processControlValves'>Process & Control Valves</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/Electromotive'>Electromotive Valves</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/Pneumatics'>Pneumatics & Process Interfaces</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/Sensors'>Sensors, Transmitters & Controllers</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/Microfluidic'>Microfluidic Valve & Pumps</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/massflow-controllers'>Mass Flow Controllers</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/filtraiton-modules'>Filtration Modules</Link></Dropdown.Item>
        <Dropdown.Item><Link className='dropdown-links' to='/additional-products'>Additional Products</Link></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownMenu;