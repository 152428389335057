import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Electromotive = () => {
  return (
    <Container className='mt-4 burkert-img'>
      <Row>
        <img
          src='../assets/images/burkert-logo.png'
          alt='burkert logo'
          className='burkert-logo'
        />
        <Col xs={12} sm={12} md={12} lg={12} className='mt-4'>
        <iframe className='video' width="560" height="315" src="https://www.youtube.com/embed/YUXGi5dq2Mc" title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <p className="mt-4">
        Burkert manufactures valves for the control of both liquids and gases.  Some of the types of valves include Ball, Diaphram, Angle-Seat, Globe, and Butterfly.  For nearly every Burkert valve, there is a diverse selection of mating actuators.  The basic types of activation are manual, solenoid, and pneumatic. 
        Burkert offers activation states that are either on/off or control (with analog electrical inputs).
        </p>
        <p>
        Burkert designs their products to serves the general needs of the industry, as well as specific needs for the hygienic and cryogenic industries. 
        The valves represent quality and are known to be the most sophisticated, most trusted, and most reliable in the industry.
        </p>
        <p>
        The line of Electromotive Valves is very diverse.  Electromotive Valves are the integration of a motorized actuator and a valve. 
        The motor is electrically powered to move the actuator the required amount to move and control the valve position.
         Burkert Electromotive Valves are offered as disc valves, angle seat valves, diaphragm valves, globe valve, and ball valves. 
        Each type of valve action represents tradeoffs in process control and should be chosen appropriately.
        </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ul id="tabs">
            <li>
              <a
                href='https://eagle-sensors.com/burkert-type-angle-seat-valves/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/angle.jpg'
                  alt='Angle Seat Valves'
                />
                <br />
                Angle Seat Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-diaphragm-valves-2/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/diaphram.jpeg'
                  alt='Diaphram Valves'
                />
                <br />
                Diaphram Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-diaphragm-valves-2/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/globe.jpg'
                  alt='Globe Valves'
                />
                <br />
                Globe Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-electromotive-ball-valves/'
                rel='noreferrer'
              >
                <img src="../assets/images/ball.jpeg" alt="ball valves" />
                <br />
                Ball Valves
              </a>
              <br />
            </li>
            <li>
              <a
                href='https://eagle-sensors.com/burkert-disc-valves-2/'
                rel='noreferrer'
              >
                <img
                  src='../assets/images/disc.jpeg'
                  alt='Disc Valves'
                />
                <br />
                Disc Valves
              </a>
              <br />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Electromotive